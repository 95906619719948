@import './style.scss';

#return-arrow i.fa {
  float: left;
  padding-top: 2px;
}

.main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}

.main #content {
  margin-top: 275px;
}

.login-page-mc .card-bg {
  padding: 12px;
  width: 430px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0 1px rgba(255, 255, 255, 0.8) inset;
}

.minimal .card-header, .minimal .card-footer {
  background: #FFF;
  border-color: #eee;
}

.card-header {
  position: relative;
  padding: 0;
  min-height: 40px;
  line-height: 40px;
  color: #666;
  font-size: 13px;
  font-weight: 600;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 2px 2px 0 0;
  background: #fafafa;
}

.card-title {
  margin-top: 0;
  margin-bottom: 0;
  color: inherit;
  font-size: 13px;
  padding-left: 15px;
}

form {
  margin: 0;
  padding: 0;
}


body.login-page-mc .card {
  width: 400px;
  margin: 0 auto;
  position: relative;
}

.minimal .card {
  background: #f8f8f8;
  border: 10px solid #9C9C9C;
  border: none;
  border-radius: 0;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1), -1px -2px 1px rgba(0, 0, 0, 0.1);
}

.card-header .card-title .fa, .card-header .card-title .fa {
  padding-right: 8px;
  font-size: 16px;
  top: 2px;
}

.card-title > .fa, .card-title > .fa, .card-title > .fa, .card-title > .imoon {
  min-width: 25px;
  margin-right: 8px;
  border-right: 1px solid #ddd;
  /* color: #a87fc6; */
  font-size: 16px;
  top: 2px;
}

.fa, .glyphicons, .imoon, .fa, .iconsweets {
  opacity: 1;
  transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -webkit-transition: opacity 0.3s ease-in;
}

.card-body {
  position: relative;
}

.card-body {
  padding: 15px;
}

body.login-page-mc .login-avatar {
  border-bottom: 1px dashed #ddd;
  margin: 0 0 15px;
  padding-bottom: 16px;
}

body.login-page-mc .login-avatar img {
  display: block;
  margin: 0 auto;
  padding: 10px 70px;
  border: 2px solid #DDD;
}

.fa, .glyphicons, .imoon, .fa, .iconsweets {
  opacity: 1;
  transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -webkit-transition: opacity 0.3s ease-in;
}

.input-group-addon {
  min-width: 40px;
  /* color: #a287d4; */
  background-color: #fafafa;
  border-radius: 0;
  border-color: #DDD;
}

a {
  color: #666666;
}

.btn {
  color: white;
  outline: none;
  line-height: 1.47;
  border-radius: 2px;
}

.btn-default {
  text-shadow: none;
}

.btn-gradient {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.07) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.18);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
  background-repeat: repeat-x;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 1%, rgba(255, 255, 255, 0.15) 100%);
}

.p5 {
  padding: 5px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.btn-default {
  color: #555555;
}

.btn-default {
  color: #555555 !important;
}

.pull-right {
  padding-top: 0px;
}

.btn-default.btn-gradient {
  background-color: #f0f0f0;
}

.btn-default.btn-gradient {
  text-shadow: 0 1px #ffffff;
  border-color: #cccccc rgba(0, 0, 0, 0.19) rgba(0, 0, 0, 0.18);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 10%, rgba(255, 255, 255, 0.1) 100%);
}

.lh30 {
  line-height: 30px !important;
}

.fs12 {
  font-size: 12px !important;
}

.text-muted {
  color: #777;
}

a {
  font-weight: normal;
  opacity: 1 !important;
}

#return-arrow {
  color: #555;
  padding-left: 15px;
  padding-top: 15px;
  position: absolute;
  opacity: 0.7;
  cursor: pointer;
  display: block;
  z-index: 1050;
  top: 0;
  left: 0;
}

.text-light {
  color: white !important;
}

#return-arrow span {
  float: left;
  padding-left: 15px;
  padding-top: 0px;
  font-size: 16px;
}

.login-page-mc{
  background-color: #1E5F79;
  background-image: url('../../../variants/white_label/img/backgrounds/login_background.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: center;
}